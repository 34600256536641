import React from "react";

import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, makeStyles
} from "@material-ui/core";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

import { SetPriceSchema } from "../../../utility/validationSchema";

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    paddingRight: spacing(2),
    paddingBottom: spacing(2),
    paddingLeft: spacing(2),
    "& > :not(:last-child)": {
      marginBottom: spacing(1)
    }
  },
  error: {
    color: "red"
  }
}));

const SetPricePerKmDialog = ({
  open,
  handleClose,
  handleSubmit,
  price
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("settings.setPricePerKmTitle")}</DialogTitle>
      <Formik
        validationSchema={SetPriceSchema}
        initialValues={{ price }}
        onSubmit={(values, { setSubmitting }) => {
          const { price } = values;

          setSubmitting(false);
          handleSubmit(price);
        }}
      >
        {({
          handleBlur,
          handleChange,
          isSubmitting,
          submitForm,
          errors,
          touched,
          values
        }) => (
          <>
            <DialogContent>
              <form className={classes.container}>
                <TextField
                  type="number"
                  name="price"
                  label={t("settings.setPricePerKm")}
                  value={values.price}
                  error={errors.price && touched.price}
                  helperText={touched.price && t(errors.price)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                />
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                {t("abort")}
              </Button>
              <Button onClick={submitForm} color="primary" disabled={isSubmitting}>
                {t("continue")}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default SetPricePerKmDialog;

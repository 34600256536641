import React, { useState } from "react";

import clsx from "clsx";
import { isSameDay, endOfWeek, startOfWeek, isWithinInterval } from "date-fns";
import { makeStyles, TextField } from "@material-ui/core";
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";
import { DesktopDatePicker, PickersDay, PickersDayProps, LocalizationProvider } from "@material-ui/pickers";
import { useTranslation } from "react-i18next";
import { cs, enUS } from "date-fns/locale";

const useStyles = makeStyles((theme) => ({
  highlight: {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  firstHighlight: {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  },
  endHighlight: {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  },
}));

const WeekPicker = ({
  value,
  handleChangeValue,
  renderInput
}) => {
  const classes = useStyles();

  const { i18n, t } = useTranslation();
  const pickerLocale = i18n.language === "cs" ? cs : enUS;

  const [selectedDate, handleDateChange] = useState(new Date());

  const makeJSDateObject = (date) => {
    if (date instanceof Date) {
      return new Date(date.getTime());
    }

    return date;
  }

  const renderWeekPickerDay = (
    date,
    _selectedDates,
    DayComponentProps
  ) => {
    const dateClone = makeJSDateObject(date);
    const selectedDateClone = makeJSDateObject(selectedDate ?? new Date());

    const start = startOfWeek(selectedDateClone, { locale: pickerLocale });
    const end = endOfWeek(selectedDateClone, { locale: pickerLocale });

    const dayIsBetween = isWithinInterval(dateClone, { start, end });
    const isFirstDay = isSameDay(dateClone, start);
    const isLastDay = isSameDay(dateClone, end);

    return (
      <PickersDay
        {...DayComponentProps}
        disableMargin
        className={clsx({
          [classes.highlight]: dayIsBetween,
          [classes.firstHighlight]: isFirstDay,
          [classes.endHighlight]: isLastDay,
        })}
      />
    );
  };

  return (
    <LocalizationProvider dateAdapter={DateFnsAdapter} locale={pickerLocale}>
      <DesktopDatePicker
        value={selectedDate}
        onChange={handleDateChange}
        renderDay={renderWeekPickerDay}
        cancelLabel={t("abort")}
        okLabel={t("save")}
        renderInput={renderInput}
        inputFormat="'Tyden' d.MM"
      />
    </LocalizationProvider>
  );
}
export default WeekPicker;

import React from "react";

import { CardActionArea, Typography, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { formatDistanceToNow } from "date-fns";
import { cs, enUS } from "date-fns/locale";

const useStyles = makeStyles(({ spacing }) => ({
  title: {
    color: "rgb(70, 70, 70)",
    fontWeight: "bold"
  },
  date: {
    color: "rgb(100, 100, 100)"
  },
  root: {
    paddingLeft: "5px",
    paddingRight: "5px"
  }
}));

const OverviewNewsItem = ({ title, publishedAt, link }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <CardActionArea
      className={classes.root}
      onClick={() => window.open(link, "_blank")}
    >
      <Typography className={classes.title}>
        {title}
      </Typography>
      <Typography className={classes.date}>
        {formatDistanceToNow(new Date(publishedAt), {
          includeSeconds: true,
          addSuffix: true,
          locale: t("locale") === "cs" ? cs : enUS
        })}
      </Typography>
    </CardActionArea>
  );
};

export default OverviewNewsItem;

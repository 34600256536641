import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Switch } from "@material-ui/core";

const PrimarySwitch = withStyles(({ spacing, palette, transitions }) => ({
    root: {
        width: 50,
        height: 26,
        padding: 0,
        margin: spacing(1)
    },
    switchBase: {
        padding: 1,
        "&$checked": {
            transform: "translateX(23px)",
            color: palette.common.white,
            "& + $track": {
                backgroundColor: palette.primary.light,
                opacity: 1,
                border: "none"
            }
        },
        "&$focusVisible $thumb": {
            color: palette.primary.light,
            border: "6px solid #fff"
        }
    },
    thumb: {
        width: 24,
        height: 24
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${palette.grey[400]}`,
        backgroundColor: palette.grey[200],
        opacity: 1,
        transition: transitions.create(["background-color", "border"])
    },
    checked: {},
    focusVisible: {}
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

export default PrimarySwitch;

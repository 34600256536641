import React from "react";

import {
  Card, Grid, Typography, makeStyles
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import OverviewNewsItem from "./OverviewNewsItem";
import Placeholder from "../../common/Placeholder";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    padding: spacing(2)
  },
  items: {
    "& > :not(:first-child)": {
      marginTop: spacing(1)
    }
  }
}));

const OverviewNewsCard = ({
  news,
  loading
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card className={classes.root}>
      <Typography variant="h6" gutterBottom>
        {t("news")}
      </Typography>
      <Placeholder
        loading={loading}
        render={() => (
          <Grid className={classes.items} container spacing={0} direction="column">
            {news.map(({ title, publishedAt, link }) => (
              <Grid item xs key={publishedAt}>
                <OverviewNewsItem
                  title={title}
                  publishedAt={publishedAt}
                  link={link}
                />
              </Grid>
            ))}
          </Grid>
        )}
      />
    </Card>
  );
};

export default OverviewNewsCard;

import React from "react";
import { useTranslation } from "react-i18next";

import { Fab, makeStyles } from "@material-ui/core";

import PaneActions from "../PaneActions";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%"
  },
  fab: {
    width: "46%",
    marginLeft: "7px",
    marginRight: "7px",
  }
}));

const JourneySummaryActions = ({
  isCargoEditorVisible,
  isCompleteButtonDisabled,
  onCargoEditorToggle,
  onCompleteButtonClick
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <PaneActions className={classes.root}>
      <Fab
        className={classes.fab}
        color="primary"
        variant="extended"
        size="large"
        onClick={onCargoEditorToggle}
      >
        {
          isCargoEditorVisible
            ? t("webapp:journey.summary.showJourneyMap")
            : t("webapp:journey.summary.hideJourneyMap")
        }
      </Fab>
      <Fab
        className={classes.fab}
        color="primary"
        variant="extended"
        size="large"
        disabled={isCompleteButtonDisabled}
        onClick={onCompleteButtonClick}
        data-cy="complete-journey"
      >
        {t("webapp:journey.summary.complete")}
      </Fab>
    </PaneActions>
  );
};

export default JourneySummaryActions;
